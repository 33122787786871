import React from "react";
import Card from "@mui/material/Card";
import Footer from "../../../components/Footer";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import useThreads from "./useThreads";
import DataTable from "../../../components/DataTable/DataTable";
import {API_URL} from "../../../config";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import MDInput from "../../../components/MDInput";
import moment from "moment";
import {NavLink} from "react-router-dom";

const Threads = () => {

    const {actions, filters, setFilters} = useThreads();

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={2} pb={3}>
                <MDBox mb={3}>
                    <Card>
                        <DataTable
                            endpoint={`${API_URL}/threads`}
                            title="Hilos"
                            description="Visualiza los hilos creados."
                            sortBy="created_at"
                            sortDirection="desc"
                            filters={filters}
                            actions={actions}
                            colData={[
                                {
                                    name: 'id',
                                    label: 'ID',
                                    options: {
                                        display: 'excluded',
                                        filter: false
                                    }
                                },
                                {
                                    name: 'thread_id',
                                    label: 'ID',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => (
                                            <NavLink to={`/hilos/${tableMeta.rowData[0]}`} key={`hilo-${tableMeta.rowData[0]}`} style={{fontWeight: 500}}>{value}</NavLink>
                                        )
                                    }
                                },
                                {
                                    name: 'created_at',
                                    label: 'Creado',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                                        filter: true,
                                        filterType: 'custom',
                                        customFilterListOptions: {
                                            render: v => {
                                                if (v[0] && v[1]) {
                                                    return `De: ${v[0]}, A: ${v[1]}`;
                                                } else if (v[0]) {
                                                    return `De: ${v[0]}`;
                                                } else if (v[1]) {
                                                    return `A: ${v[1]}`;
                                                }
                                                return false;
                                            },
                                            update: (filterList, filterPos, index) => {

                                                if (filterPos === 0) {
                                                    filterList[index].splice(filterPos, 1, '');
                                                } else if (filterPos === 1) {
                                                    filterList[index].splice(filterPos, 1);
                                                } else if (filterPos === -1) {
                                                    filterList[index] = [];
                                                }

                                                let newFilters = () => (filterList);
                                                setFilters(newFilters);

                                                // return filterList;
                                            },
                                        },
                                        filterOptions: {
                                            names: [],
                                            logic(date, filters) {
                                                if (filters[0] && filters[1]) {
                                                    return date < filters[0] || date > filters[1];
                                                } else if (filters[0]) {
                                                    return date < filters[0];
                                                } else if (filters[1]) {
                                                    return date > filters[1];
                                                }
                                                return false;
                                            },
                                            display: (filterList, onChange, index, column) => (
                                                <div>
                                                    <MDTypography variant="button"
                                                                  fontWeight="regular">Created</MDTypography>
                                                    <FormGroup row style={{marginTop: 10}}>
                                                        <MDInput
                                                            label="De"
                                                            type="date"
                                                            variant="standard"
                                                            value={filterList[index][0] || ''}
                                                            onChange={event => {
                                                                filterList[index][0] = event.target.value;
                                                                onChange(filterList[index], index, column);
                                                            }}
                                                            style={{width: '45%', marginRight: '5%'}}
                                                        />
                                                        <MDInput
                                                            label="A"
                                                            type="date"
                                                            variant="standard"
                                                            value={filterList[index][1] || ''}
                                                            onChange={event => {
                                                                filterList[index][1] = event.target.value;
                                                                onChange(filterList[index], index, column);
                                                            }}
                                                            style={{width: '50%'}}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            ),
                                        },
                                    },
                                }
                            ]}
                        />
                    </Card>
                </MDBox>
            </MDBox>
            <Footer/>
        </DashboardLayout>
    );
};

export default Threads;
