import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

const useThreads = () => {

    const [filters, setFilters] = useState(null);

    let navigate = useNavigate();

    const actions = {
        add: false,
        edit: false,
        delete: true,
    };

    return {
        actions, filters, setFilters
    }
};

export default useThreads;
