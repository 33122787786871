import React, {useState} from "react";
import {capitalizeFirstLetter} from "../../../helpers/helpers";
import Chip from "@mui/material/Chip";
import {useNavigate} from "react-router-dom";

const useUsers = () => {

    const [filters, setFilters] = useState(null);

    let navigate = useNavigate();

    const actions = {
        add: () => navigate(`/usuarios/nuevo-usuario`),
        edit: (id) => navigate(`/usuarios/${id}/editar-usuario`),
        delete: true,
    };

    const renderStatusChip = (status) => {
        switch (status) {
            case 1:
                return <Chip size="small" color="secondary" label="Activo"
                             variant="outlined"/>;
            case 0:
                return <Chip size="small" color="warning" label="Inactivo"
                             variant="outlined"/>;
        }
    };

    return {
        actions, filters, setFilters, renderStatusChip
    }
};

export default useUsers;
