import useNewScrape from "../../scrape/new-scrape/useNewScrape";
import BaseLayout from "../../../components/BaseLayout";
import Grid from "@mui/material/Grid";
import MDBox from "../../../components/MDBox";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import MDSnackbar from "../../../components/MDSnackbar";
import React from "react";
import useViewThread from "./useViewThread";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import openaiLogo from '../../../assets/images/openai.png';
import {Form, Formik} from "formik";
import AIForm from "./components/AIForm/AIForm";
import form from "./schemas/form";
import validations from "./schemas/validations";
import initialValues from "./schemas/initialValues";

const ViewThread = () => {

    const {messages, isInitLoading, isLoading, show, snackbarConfig, renderMessages, startConversation, loadMore, toggleSnackbar} = useViewThread();

    const {formId, formField} = form;
    const currentValidation = validations;

    const handleSubmit = (values, actions) => {
        loadMore(values, actions);
    };

    return (
        <BaseLayout>
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={10}>
                    <MDBox mb={3}>
                        <Card id="view-thread" sx={{overflow: "visible"}}>
                            {
                                isInitLoading ?
                                    <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                                           alignItems="center">
                                        <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                    </MDBox> :
                                    <MDBox p={3}>
                                        {
                                            messages.length > 0 ?
                                                <MDBox>
                                                    <MDBox sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                                        <img src={openaiLogo} alt="OpenAI Logo"
                                                         style={{width: '30px', height: 'auto', marginRight: 10}}/>
                                                        <MDTypography variant="h4">Agente IA</MDTypography>
                                                    </MDBox>
                                                    <style>
                                                        {
                                                            `p {padding-top: 8px; padding-bottom: 8px;} ol {padding-left: 24px;}`
                                                        }
                                                    </style>
                                                    {renderMessages()}
                                                    <Formik
                                                        initialValues={initialValues}
                                                        validationSchema={currentValidation}
                                                        onSubmit={handleSubmit}
                                                    >
                                                        {({values, errors, touched, isSubmitting}) => (
                                                            <Form id={formId} autoComplete="off">
                                                                <AIForm formData={{values, touched, formField, errors}}/>
                                                                <MDBox>
                                                                    <MDButton fullWidth color="secondary" variant="contained" type="submit" disabled={isLoading}>
                                                                        {isLoading && <CircularProgress size={18} color="white" sx={{marginRight: 1}}/>}
                                                                        {
                                                                            isLoading ?
                                                                                'PENSANDO' :
                                                                                'CARGAR MAS'
                                                                        }
                                                                    </MDButton>
                                                                </MDBox>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </MDBox> :
                                                <MDBox sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    padding: 15
                                                }}>
                                                    <img src={openaiLogo} alt="OpenAI Logo"
                                                         style={{width: '50px', height: 'auto'}}/>
                                                    <MDTypography variant="h2">Conversar con Agente IA</MDTypography>
                                                    <MDTypography style={{marginBottom: 16}} variant="body2">El agente
                                                        ha analizado los archivos y está listo para dar información
                                                        sobre ellos.</MDTypography>
                                                    <MDButton color="secondary" variant="contained" onClick={startConversation} disabled={isLoading}>
                                                        {isLoading && <CircularProgress size={18} color="white" sx={{marginRight: 1}}/>}
                                                        {
                                                            isLoading ?
                                                                'INICIANDO' :
                                                                'INICIAR CONVERSACIÓN'
                                                        }
                                                    </MDButton>
                                                </MDBox>
                                        }
                                    </MDBox>
                            }
                        </Card>
                    </MDBox>
                </Grid>
            </Grid>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </BaseLayout>
    );
};

export default ViewThread;
