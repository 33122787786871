/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../../../../../components/FormField";


function AIForm({formData}) {

    const {formField, values, errors, touched} = formData;
    const {city, type, month} = formField;

    const {
        city: cityV,
        type: typeV,
        month: monthV
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={city.type}
                        label={city.label}
                        name={city.name}
                        value={cityV}
                        placeholder={city.placeholder}
                        error={errors.city && touched.city}
                        success={cityV.length > 0 && !errors.city}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={type.type}
                        label={type.label}
                        name={type.name}
                        value={typeV}
                        placeholder={type.placeholder}
                        error={errors.type && touched.type}
                        success={typeV.length > 0 && !errors.type}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={month.type}
                        label={month.label}
                        name={month.name}
                        value={monthV}
                        placeholder={month.placeholder}
                        error={errors.month && touched.month}
                        success={monthV.length > 0 && !errors.month}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default AIForm;
