import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../../config";
import MDTypography from "../../../components/MDTypography";
import {useParams} from "react-router-dom";
import Markdown from 'react-markdown'
import Pusher from "pusher-js";

const useViewThread = () => {

    const { id } = useParams();

    const [isInitLoading, setIsInitLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [messages, setMessages] = useState([]);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    useEffect(() => {
        const init = async () => {
            setIsInitLoading(true);
            await loadMessages(50, 'asc');

            // Enable pusher logging - don't include this in production
            Pusher.logToConsole = true;

            let pusher = new Pusher('c231e14bb4a42670fa55', {
                cluster: 'us2'
            });

            let channel = pusher.subscribe('almonedapp');
            channel.bind('run-completed', async (data) => {
                setIsLoading(true);
                await loadMessages(2, 'desc');
                setIsLoading(false);
            });

            setIsInitLoading(false);
        }

        init();

    }, []);

    const loadMessages = async (limit, order) => {

        try {

            // Enviar datos a API
            const messagesResponse = await axios.get(
                `${API_URL}/list_messages`,
                {
                    params: {
                        thread_id: id,
                        limit,
                        order
                    }
                }
            );

            if(order === 'asc'){
                setMessages((prevMessages) => [
                    ...prevMessages, // Mensajes existentes
                    ...messagesResponse.data.data.data // Nuevos mensajes
                ]);
            } else {
                setMessages((prevMessages) => [
                    ...prevMessages, // Mensajes existentes
                    ...messagesResponse.data.data.data.reverse() // Nuevos mensajes
                ]);
            }


        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Hilo',
                color: 'warning'
            });

            setShow(true);

        }
    };

    const startConversation = async () => {

        try {

            setIsLoading(true);

            // Enviar datos a API
            const sendResponse = await axios.post(
                `${API_URL}/send_message`,
                {
                    thread_id: id
                }
            );

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Hilo',
                color: 'warning'
            });

            setShow(true);

        }
    }

    const loadMore = async (values, actions) => {

        console.log(values);

        try {

            setIsLoading(true);

            // Enviar datos a API
            const loadMoreResponse = await axios.post(
                `${API_URL}/load_more`,
                {
                    thread_id: id,
                    ...values
                }
            );

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Hilo',
                color: 'warning'
            });

            setShow(true);

        }
    }

    const renderMessages = () => {
        return messages.map((message) => {
            if(message.role === 'user'){
                return <MDTypography variant="body2" style={userStyles }>{message.content[0] && message.content[0].text.value}</MDTypography>
            } else {
                return <MDTypography variant="body2">
                    <Markdown style={{ fontSize: '10px' }}>{message.content[0] && message.content[0].text.value}</Markdown>
                </MDTypography>
            }
        })
    };

    const userStyles = {
        textAlign: 'left',
        padding: 16,
        backgroundColor: '#f5f5f5',
        borderRadius: 20,
        marginTop: 16,
        marginBottom: 16,
        marginLeft: '30%'
    }

    const assistantStyles = {
        textAlign: 'left',
        marginTop: 16,
        marginBottom: 16
    }

    return {
        messages,
        isInitLoading,
        isLoading,
        show,
        snackbarConfig,
        renderMessages,
        startConversation,
        loadMore,
        toggleSnackbar
    }
};

export default useViewThread;
