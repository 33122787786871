/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-user-form",
  formField: {
    name: {
      name: "name",
      label: "Nombre",
      type: "text",
      errorMsg: "El nombre es obligatorio.",
    },
    email: {
      name: "email",
      label: "Correo electrónico",
      type: "email",
      errorMsg: "El correo es obligatorio.",
      invalidMsg: "El correo no es válido.",
    },
    password: {
      name: "password",
      label: "Contraseña",
      type: "password",
      errorMsg: "La contraseña es obligatoria.",
      invalidMsg: "Debe contener al menos 8 carateres",
    },
    password_confirmation: {
      name: "password_confirmation",
      label: "Confirmar contraseña",
      type: "password",
      errorMsg: "La confirmaión es obligatoria.",
      invalidMsg: "Las contraseñas no coinciden.",
    },
  },
};

export default form;
