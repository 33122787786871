import form from "./schemas/form";
import validations from "./schemas/validations";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../../config";
import {capitalizeFirstLetter} from "../../../helpers/helpers";

const useNewUser = () => {

    const {formId, formField} = form;
    const currentValidation = validations;

    const [isLoading, setIsLoading] = useState(false);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });

    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const handleSubmit = async (values, actions) => {

        try {

            // Enviar datos a API
            await axios.post(
                `${API_URL}/users`,
                {...values}
            );

            actions.resetForm();

            setSnackbarConfig({
                message: 'Usuario registrado',
                icon: 'notifications',
                title: 'Usuarios',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Users',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return {
        currentValidation,
        formId,
        formField,
        isLoading,
        show,
        snackbarConfig,
        handleSubmit,
        toggleSnackbar
    };
};

export default useNewUser;
