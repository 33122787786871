import form from "./schemas/form";
import validations from "./schemas/validations";
import {useState} from "react";
import axios from "axios";
import {API_URL} from "../../../config";
import { useNavigate } from 'react-router-dom';

const useNewScrape = () => {

    const navigate = useNavigate();

    const {formId, formField} = form;
    const currentValidation = validations;

    const [isLoading, setIsLoading] = useState(false);
    const [urls, setUrls] = useState(null);
    const [thread, setThread] = useState(null);

    const [step, setStep] = useState(0);

    const [retryTime, setRetryTime] = useState(5); // Tiempo inicial de reintento
    const [showRetryMessage, setShowRetryMessage] = useState(false); // Para mostrar el mensaje de reintento

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const handleSubmit = async (values, actions) => {

        try {

            // Enviar datos a API
            const scrapeResponse = await axios.post(
                `${API_URL}/scrape`,
                {...values}
            );

            actions.resetForm();

            console.log(scrapeResponse.data.data);

            setUrls(scrapeResponse.data.data.files);
            setStep(1);

            await checkVectorStoreStatus(scrapeResponse.data.data.vectorStore, scrapeResponse.data.data.thread);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Propiedades',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    const checkVectorStoreStatus = async (vectorStoreId, threadId) => {

        try {

            // Enviar datos a API
            const vectorStoreResponse = await axios.get(
                `${API_URL}/vector_stores/${vectorStoreId}`
            );

            // Checar estatus del vector store
            if(vectorStoreResponse.data.data.status !== 'completed'){

                // Actualizar el mensaje de reintento
                setShowRetryMessage(true);
                setRetryTime(5); // Reiniciar el contador

                // Iniciar el contador
                const interval = setInterval(() => {
                    setRetryTime((prev) => {
                        if (prev <= 1) {
                            clearInterval(interval);
                            return 5; // Reiniciar el tiempo
                        }
                        return prev - 1; // Decrementar el contador
                    });
                }, 1000);

                // Reintentar en 5 segundos
                setTimeout(async () => {
                    await checkVectorStoreStatus(vectorStoreId, threadId);
                    clearInterval(interval);
                }, 5000);

            } else {
                console.log('Hilo', thread);

                // Navegar a la nueva URL
                setShowRetryMessage(false);
                navigate(`/hilos/${threadId}`);
            }

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Procesamiento de Archivos',
                color: 'warning'
            });

            setShow(true);

        }
    };

    return {
        step,
        urls,
        thread,
        currentValidation,
        formId,
        formField,
        isLoading,
        showRetryMessage,
        retryTime,
        show,
        snackbarConfig,
        handleSubmit,
        toggleSnackbar
    };
};

export default useNewScrape;
