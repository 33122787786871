/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {Form, Formik} from "formik";
import React from "react";
import initialValues from "./schemas/initialValues";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import MDSnackbar from "../../../components/MDSnackbar";
import useNewScrape from "./useNewScrape";
import NewScrapeForm from "./components/NewScrapeForm/NewScrapeForm";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RefreshIcon from '@mui/icons-material/Refresh';
import DoneIcon from '@mui/icons-material/Done';
import Chip from "@mui/material/Chip";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";

// Settings page components

const NewScrape = () => {

    const {step, urls, showRetryMessage, retryTime, show, currentValidation, formId, formField, isLoading, snackbarConfig, handleSubmit, toggleSnackbar} = useNewScrape();

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={10}>
                    <MDBox mb={3}>
                        <Card id="new-scrape" sx={{overflow: "visible"}}>
                            {
                                isLoading ?
                                    <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                                           alignItems="center">
                                        <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                    </MDBox> :
                                    step === 0 ?
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={currentValidation}
                                        onSubmit={handleSubmit}
                                    >
                                        {({values, errors, touched, isSubmitting}) => (
                                            <Form id={formId} autoComplete="off">
                                                <MDBox p={3}>
                                                    <MDTypography sx={{textAlign: 'center'}} variant="h4">Búsqueda de Almonedas</MDTypography>
                                                    <MDTypography sx={{textAlign: 'center'}} variant="body2">Ingresa la liga base para buscar documentos PDF. Esta operación puede tardar varios minutos dependiendo de la cantidad de PDFs encontrados.</MDTypography>
                                                </MDBox>
                                                <MDBox pb={3} px={3}>
                                                    <NewScrapeForm formData={{values, touched, formField, errors}} />
                                                    <MDBox mt={1} width="100%" display="flex"
                                                           justifyContent="center">
                                                        <MDBox>
                                                            <MDButton
                                                                disabled={isSubmitting}
                                                                variant="gradient"
                                                                color="secondary"
                                                                type="submit"
                                                            >
                                                                {isSubmitting &&
                                                                <CircularProgress size={18} color="white"
                                                                                  sx={{marginRight: 1}}/>}
                                                                Analizar Documentos
                                                            </MDButton>
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>

                                            </Form>
                                        )}
                                    </Formik> :
                                    <MDBox>
                                        <MDBox p={3} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                            <CloudUploadIcon fontSize="large" color="secondary" />
                                            <MDTypography sx={{textAlign: 'center'}} mt={2} variant="h4">Los Documentos Han Sido Cargados</MDTypography>
                                            <MDTypography sx={{textAlign: 'center'}} variant="body2">El agente de IA comenzará a procesar los documentos para entender sus contenidos. Tómate un café mientras esperas, esta página se actualizará sola al terminar el procesamiento de archivos.</MDTypography>
                                        </MDBox>
                                        <MDBox p={2} mb={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                            <MDTypography variant="h5" mb={1}>Estatus de Agente IA</MDTypography>
                                            <Chip icon={<RefreshIcon />} variant="outlined" label="Procesando Archivos" />
                                            {
                                                showRetryMessage &&
                                                <MDTypography sx={{flexGrow: 1, textAlign: 'right', fontSize: 12}} mt={1} variant="body2">Recargando en {retryTime}...</MDTypography>
                                            }
                                        </MDBox>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <MDTypography variant="h6">
                                                    Documentos cargados
                                                </MDTypography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <MDTypography variant="body2">
                                                    { urls.join(', ') }
                                                </MDTypography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </MDBox>
                            }
                        </Card>
                    </MDBox>
                </Grid>
            </Grid>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </DashboardLayout>
    );
};

export default NewScrape;
