/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "../../../../../components/MDBox";
import FormField from "../../../../../components/FormField";
import React from "react";
import MDTypography from "../../../../../components/MDTypography";
// Material Dashboard 2 PRO React components


function NewScrapeForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {url} = formField;
    const {
        url: urlV
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={url.type}
                        label={url.label}
                        name={url.name}
                        value={urlV}
                        error={errors.url && touched.url}
                        success={urlV && urlV.length > 0 && !errors.url}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default NewScrapeForm;
