import {
    SIGNIN_SUCCESS,
    SIGNOUT_SUCCESS
} from "../actionTypes";

export const signIn = (userId, name) => async dispatch => {
    dispatch({
        type: SIGNIN_SUCCESS,
        payload: { userId, name }
    });
};

// Acción para cerrar sesión
export const signOut = () => async dispatch => {
    dispatch({ type: SIGNOUT_SUCCESS });
};

